import React from 'react'
import styles from './hamburger.module.css'

const HamburgerIcon = (props) => (
  <div className={styles.hamburger}>
    <span className={!props.showNav ? styles.burgerLineOne : styles.burgerLineOneOpen} />
    <span className={!props.showNav ? styles.burgerLineTwo : styles.burgerLineTwoOpen} />
    <span className={!props.showNav ? styles.burgerLineThree : styles.burgerLineThreeOpen} />
  </div>
)

export default HamburgerIcon
