import {Link} from 'gatsby'
import React from 'react'
import Icon from './icon'
import {cn} from '../lib/helpers'

import styles from './header.module.css'

const Header = ({onHideNav, onShowNav, showNav, siteTitle}) => (
  <div className={styles.root}>
    <div className={styles.wrapper}>
      <button className={styles.toggleNavButton} onClick={showNav ? onHideNav : onShowNav} aria-label="Menu Button">
        <Icon symbol='hamburger' showNav={showNav} />
      </button>
      <div className={styles.mobileBrand}>
        <Link to='/' className="brandMark" aria-current="false"><span>	&#60; </span> BLAKEMOORE <span>/&#62;</span></Link>
      </div>
      <nav className={cn(styles.nav, showNav && styles.showNav)}>
        <ul>
          <li>
            <Link to='/about/'>About</Link>
          </li>
          <li>
            <Link to='/work/'>Work</Link>
          </li>
          <li className={styles.brand}>
            <Link to='/' className="brandMark" aria-current="false"><span>	&#60; </span> BLAKEMOORE <span>/&#62;</span></Link>
          </li>
          <li>
            <Link to='/professional/'>Résumé</Link>
          </li>
          <li>
            <Link to='/contact/'>Connect</Link>
          </li>
        </ul>
      </nav>
    </div>
  </div>
)

export default Header
