import React from 'react'
import Header from './header'
import {Link} from 'gatsby'
import blakeBackground from '../assets/images/blake-footer-bg.jpg'
import '../styles/layout.css'
import styles from './layout.module.css'
import Img from "gatsby-image/withIEPolyfill"

const Layout = (props) => (
  <>
    <Header siteTitle={props.siteTitle} onHideNav={props.onHideNav} onShowNav={props.onShowNav} showNav={props.showNav} />
    <div className={styles.content}>{props.children}</div>
    <footer className={styles.footer}>
      <div className={styles.footerWrapper}>
        <div className={styles.footerCopy}>
          <h2>Creative, Results-Driven <br />
          <u>Front End</u> Developer</h2>
          <p>While technically a full stack developer, I really prefer building User interfaces, web animations and design systems over APIs and algorithms.</p>
          <Link className={styles.contactMeLink} to="/contact/">Lets Chat >> </Link>
        </div>
        <Img fluid={props.footerImage ? props.footerImage.childImageSharp.fluid : {}} alt="Footer Photo" className={styles.footerPhoto} />
        <div className={styles.siteInfo}>
          © {new Date().getFullYear()}, Built with &hearts; in Los Angeles
      </div>
      </div>
    </footer>
  </>
)

export default Layout
